.directors-container {
    /* width: 880px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.directors-desktop-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.directors-desktop-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 7vh;
    background-color: rgba(255,255,255, 0.85);
}
.directors-desktop-card-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    height: 60vh;
}
.directors-desktop-card-column::-webkit-scrollbar, .directors-scroll-knollwood::-webkit-scrollbar {
    display: none;
  }
.directors-scroll-knollwood {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    height: 23vh;
}

.directors-desktop-card-container:hover {
    background-color: skyblue;
    font-weight: 800;
}

.directors-desktop-location, .directors-desktop-subheader-location {
    width: 10vw;
}
.directors-desktop-lotNumber, .directors-desktop-subheader-lotNumber {
    width: 9vw;
}
.directors-desktop-price, .directors-desktop-subheader-price {
    width: 10vw;
    padding-left: 2vw;
    text-align: center;
}
.directors-desktop-section, .directors-desktop-subheader-section {
    width: 5vw;
}
.directors-desktop-spaces, .directors-desktop-subheader-spaces {
    width: 6vw;
}
.directors-desktop-lotName, .directors-desktop-subheader-lotName {
    width: 20vw;
}
.directors-desktop-header {
    font-size: 6vh;
    font-weight: 800;
    padding: 0 1vw 0 1vw;
    text-align: center;
    width: 50vw;
    margin: 1vh;
    color: black;
    border-radius: 5vh;
    background-image: radial-gradient(white,  rgba(255,255,255, 0.7), rgba(0,0,0,0));
}
.directors-desktop-subheader {
    display: flex;
    flex-direction: row;
    text-align: center;
    border-radius: 1vh 1vh 0 0;
    background-color: skyblue;
}
.directors-desktop-subheader-item {
    font-size: 2.5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
}
.directors-desktop-item {
    text-align: center;
}
.directors-desktop-location {
    font-size: 3.5vh;
}
.directors-desktop-lotName {
    font-size: 3vh;
}
.directors-desktop-section, .directors-desktop-spaces, .directors-desktop-lotNumber {
    font-size: 4vh;
}
.directors-desktop-price {
    font-size: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.filter-directors-desktop-item {

}
.directors-desktop-button-container {
    margin-top: 1vh;
    margin-bottom: 1vh;
}
.progress-container-desktop {
    margin-top: 4vh;
}
.desktop-each-fix {
    font-size: 2vh;
    margin-left: 0.5vw;
}
.admin-knollwood-area-header {
    font-size: 3vh;
}
.admin-knollwood-header {
    font-size: 4.5vh;
}

.knollwood-section-header {
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: 3vh;
    font-weight: 600;
    color: black;
    padding: 0.5vh 1vw;
    border-radius: 5vh;
    background-image: radial-gradient(white, rgba(255,255,255, 0.7), rgba(0,0,0,0));

}

/* updated below */

.u-d-directors-container {
    height: 91vh;
    width: 1050px;
    background-color: rgba(255, 255, 255, 0.849);
    /* margin-top: 1vh; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.u-d-directors-header {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2vh 0; */
    font-size: 2.3rem;
}
.u-d-directors-location-row {
    width: 85%;
    /* margin-bottom: 0.3vh; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.8rem;
    /* background-color: violet; */
    height: 8%;
}
.u-d-directors-loading-feedback {
    /* margin-bottom: 2vh; */
    width: 85%;
    height: 2%;
    margin-top: 1%;
    /* background-color: turquoise; */

}
.u-d-directors-location-container {
    width: 99%;
    height: 80%;
    /* padding-top: 1vh; */
    /* background-color: tomato; */

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.u-d-directors-category {
    height: 100%;
    width: 49%;
    /* background-color: yellow; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.u-d-d-header {
    /* margin-top: 2%; */
    height: 7%;
    /* background-color: violet; */
    font-size: 1.6rem;
}
.u-d-d-label {
    width: 98%;
    height: 6%;
    background-color: skyblue;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2vh 2vh 0 0;
}
.u-d-d-lotlist {
    width: 98%;
    height: 92%;
    background-color: rgba(135, 207, 235, 0.473);
    overflow: scroll;
    /* padding-bottom: 4px; */

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.u-d-d-lotlist::-webkit-scrollbar {
    display: none;
  }
.u-d-d-label-item, .u-d-d-card-item {
    width: 25%;
    /* color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.u-d-d-card {
    background-color: rgba(255, 255, 255, 0.815);
    padding: 1vh 0;
    width: 98%;
    margin: 0.2vh 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.u-d-d-card-item {
    font-size: 1rem;
}
/* testimonial-d-container */