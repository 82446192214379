.m-home-container {
    /* min-height: 80vh; */
    width: 100vw;
    height: 87vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    /* z-index: 20; */
}
.m-home-scroll-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;

    background-color: var(--mobile-bg);
}

.m-home-brand {
    font-size: 2.9rem;
    /* font-weight: 800; */
    margin: 6px 0 0;
    text-align: center;
}
.m-home-contact-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* margin-bottom: 5px; */
}
.m-home-contact-name {
    font-size: 1.8rem;
    font-weight: 800;
    padding-bottom: 5px;
}
.m-home-contact-row-item {
    margin: 0 5px;
    text-decoration: none;
    font-size: 1.1rem;
}
.m-link-fix {
    text-decoration: none;
    color: black;
}
.m-home-statement {
    width: 98%;
    font-size: 1.3rem;
    text-align: center;
    color: var(--mobile-secondary);
    /* font-weight: 600; */
    margin-bottom: 10px;
    line-height: 0.9;
}
.m-home-info-top, .m-home-info-bottom {
    width: 95%;
    text-align: center;
    margin-bottom: 5px;
    line-height: 0.9;
}
.m-home-option-top {
    margin-top: 10px;
}
.m-home-info-top {
    font-size: 1.1rem;
    padding-bottom: 10px;
}
.m-home-info-bottom {
    font-size: 0.9rem;
}
.m-home-option-bottom {
    margin-bottom: 45px;
}
.m-home-option-button {
    width: 98vw;
    /* min-height: 9vh; */
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary);
    margin-bottom: 8px;
    border-radius: 15px;
    color: var(--mobile-secondary);
}
.m-home-option-icon {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-home-option-text {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    text-align: center;
}