.t-admin-container {
    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
/* }
.t-admin-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
} */
.t-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.t-login-header {
    font-size: 3.2rem;
    width: 90%;
    /* margin: auto; */
    margin: 3vh auto 2.5vh;
    height: 12vh;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.t-login-error {
    font-size: 2rem;
    font-weight: 800;
    color: rgb(250, 25, 25);
}