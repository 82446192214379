.lot-view-desktop-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    margin-left: 10vw;
    margin-top: 1vh;
    position: sticky;
    top: 0;
}
.desktop-admin-status-lots {
    margin-top: 0.5vh;
    margin-bottom: 1vh;
    width: 100%;
}

.lotcard {
    margin-bottom: 1.25vh;
    width: 50vw;
    height: 40vw;
    background-color: skyblue;
    border-radius: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.lot-desktop-main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.testbuttonfix {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 1vh; */
}
.lot-desktop-filter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255,255,255,0.9);
    width: 20vw;
    padding: 1.5vh;
    top: 0;
    /* left: 5vw; */
}
.lot-desktop-list-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    margin-left: 0vh;
    height: 90vh;
    overflow: scroll;
    padding-right: 1vh;
}
.lot-desktop-list-container::-webkit-scrollbar {
    display: none;
  }
.card-desktop-pic {

}
.card-desktop-location{
    font-size: 5vh;
    display: flex;
    flex-direction: row;
    background-color: rgba(255,255,255,0.5);
    border-radius: 8px;
    padding: 0.5vh;
}
.card-desktop-lot-name {
    border-radius: 8px;
    margin-top: 0.5vh;
    font-size: 7vh;
    padding: 0.5vh;
    -webkit-text-stroke: 2px black;
    color: white;
    font-weight: 800;
}
.card-desktop-price {
    font-weight: 600;
}
.lot-card-mainheader {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.card-desktop-subheader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.card-desktop-middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.card-desktop-side-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
}
.card-desktop-side-info-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}
.card-desktop-side-info-header {
    font-size: 3vh;
}
.card-desktop-side-info-content {
    font-size: 4.3vh;
}
.desktop-circular-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35vh;
    width: 63.5vw;
}
.admin-desktop-lot-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 15vh; */
}
.admin-edit-lot-in-card {
    display: flex;
    flex-direction: row;
    margin-top: 1.5vh;
}
.testestest {
    display: flex;
    flex-direction: column;
}
.desktop-lot-edit-success-message{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vh;
}
.buttontry {
    margin-bottom: 3vh;
}
.desktop-delete-lot-warning {
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    padding: 1vh;
    margin-top: 2vh;
}
.desktop-delete-lot-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 25vw;
    height: 6vh;
}
.desktop-upload-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.desktop-admin-lot-control-fix {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.desktop-image-upload-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5vh;
}
.deskop-image-upload-input{
    max-width: 13vw;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}
.desktop-successful-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vh;
    color: green;
    text-align: center;
}
.desktop-lot-picture {
    height: 20vh;
    width: 20vw;
}
.lotcard-container-desktop {
    margin: auto;
    /* height: 50vw; */
}

.lotcard-desktop-right, .lotcard-desktop-right-price {
    display: flex;
    flex-direction: column;
}
.card-desktop-price {
    font-size: 7vh;
}
.card-desktop-sub-price {
    font-size: 2vh;
}
.lotcard-desktop-right-price {
    align-items: flex-end;
    margin: 0.5vh;
}
.lotcard-desktop-right {
    justify-content: flex-start;
    background-color: skyblue;
    height: 40vw;
    padding-left: 1vw;
    border-radius: 0 2vh 2vh 0;
}
.lotcard-desktop-left {
    padding: 1vh;
}
.lotcard-desktop-info {
    margin-top: 1.5vh;
    margin-bottom: 3.5vh;
}
.desktop-lotcard-gradient {
    background-image: linear-gradient(to left, skyblue , rgba(0,0,0,0));
    width: 7vw;
    height: 40vw;

}
.desktop-lot-gradient-container{
    display: flex;
    flex-direction: row;
}
.desktop-admin-lot-control-fix-spacing {
    margin: 0.5vh;
}
.desktop-filter-location {
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.desktop-filter-header {
    font-size: 5vh;
    font-weight: 600;
}
.filter-desktop-location-items {
    color: black;
    font-size: 2.5vh;
}

.trythisheight {
    margin-top: 3vh;
    width: 15vw;
}
.filter-desktop-price-slider-label {
    font-size: 4vh;
    margin-top: 2vh;
    margin-bottom: 4.5vh;
    text-align: center;
}
.desktop-filtered-lots-container {
    width: 50vw;
    margin: 2vh;
}
.filter-desktop-section {
    display: flex;
    flex-direction: column;
    /* margin-top: 2vh; */
    margin-bottom: 3vh;
    justify-content: space-between;
    align-items: center;
    font-size: 3vh;
    /* margin-top: 1vh; */
}
.admin-desktop-add-lot-container {
    /* margin-left: 2vh; */
}
.desktop-search-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5vh;
    align-items: center;
}
.desktop-search-section {
    width: 13vw;
    margin-right: 0.4vw;
}
/* home-d-guest-container */
.desktop-filter-icon {
    background-color: rgba(255, 255, 255, 0.5);
    /* padding: 13px 13px 7px 13px; */
    border-radius: 0.8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.newcard-d-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.90);
    margin-bottom: 1.75vh;
}
.newcard-d-left-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.newcard-d-right-top {

}
.newcard-d-image {
    display: flex;
    height: 20vw;
    width: 20vw;
    margin: 1vh;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}
.newcard-d-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 1vh;
}
.newcard-d-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1vh;
}
.newcard-d-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1vh;
}
.newcard-d-location {
    font-size: 6vh;
}
.newcard-d-brand {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 25vw;
}
.newcard-d-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 5.5vh;
    text-align: right;
}
.newcard-d-each {
    margin-left: 0.3vw;
    font-size: 2vh;
}
.newcard-d-section-space {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 4vh;
}
.newcard-d-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.newcard-d-section-header {
    font-size: 3vh;
}
.newcard-d-section-body {
    font-size: 6vh;
}
.newcard-d-lotnumber {
    font-size: 4vh;
}
.newcard-d-price-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    background-color: rgba(255,255,255,0.5);
    border-radius: 0 0 0 2vh;
    padding: 0 0 0.2vh 0.5vh;
}
.admin-d-lot-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.admin-d-first {
    width: 14vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.admin-d-second {
    /* margin-right: vw; */
}
.admin-d-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 44vw;
}
.newcard-admin-options {
    display: flex;
    flex-direction: column;
}
.admin-edit-container {
    display: flex;
    flex-direction: column;
}
.admin-edit-bottom, .admin-edit-top {
    margin-top: 2vh;
}
.admin-edit-form-fields {
    display: flex;
    flex-direction: row;
}
.admin-edit-form-item {
    width: 7vw;
    margin: 0 1vh;
}
.admin-edit-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 2vh;
}
.admin-edit-comment-field {
    width: 22vw;
}
.edit-comment-container {
    margin-right: 2vw;
}
.newcard-d-comments {
    margin-bottom: 2vh;
    width: 80%;
}
/* new-admin-d-lot-content-container */