.gallery-tablet-container {
    background-color: rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3vh 0 5vh;
    height: 100%;
    width: 100%;
    margin-top: 5vh;
}
.gallery-tablet-gridlist-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.gallery-tablet-location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin-top: 3vh;
}
.gridlist-tablet-location-image {
    height: 100%;
    width: 100%;
}