.m-gallery-container {
    width: 100vw;
    height: 87vh;
    background-color: var(--mobile-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-gallery-height-fix {
    height: 87vh;
    width: 100vw;
    background-color: var(--secondary);
}
.m-gallery-options {
    height: 6vh;
}
.m-gallery-button-style {
    font-size: 1.35rem;
    color: rgba(255, 255, 255, 0.788);
    text-transform: none;
}
.m-gallery-content {
    /* background-color: turquoise; */
    width: 99%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
}
.m-gallery-header {
    width: 100%;
    height: 6vh;
    font-size: 1.7rem;
    font-weight: 800;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}