.about-desktop-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1050px;
    height: 91vh;
    /* margin-left: 10vw; */
    /* background-image: linear-gradient(to right, rgba(255,255,255,0),rgba(255,255,255,0.3),rgba(255,255,255,0.8),rgba(255,255,255,0.8),rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8), rgba(255,255,255,0.8),rgba(255,255,255,0.8),rgba(255,255,255,0.3),rgba(255,255,255,0)); */

}
.about-desktop-button-group {
    margin-bottom: 3vh;
    margin-top: 2vh;
}
.about-d-standard-container {
    width: 1150px;
    height: 100%;
    background-image: 
    linear-gradient(
        to right,
        rgba(255,255,255,0),
        rgba(255,255,255,0.2),
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.2),
        rgba(255,255,255,0));    /* padding: 5px 15vw; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.about-d-standard-info {
    text-align: center;
    width: 50%;
    font-size: 2vh;
}
.about-d-middle-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* background-color: springgreen; */
}
.about-d-gl-pic {
    height: 35vh;
    margin-left: 2%;

}
.about-d-top-container, .about-d-bottom-container {
    text-align: center;
    /* background-color: slateblue; */
}
.about-d-signature-container {
    text-align: right;
    margin-top: 2vh;
}
.about-d-buyers-container {
    max-width: 60vw;
    padding-bottom: 4vh;
}
.about-d-panel-quesiton {
    font-weight: 400;
}
.about-d-panel-answer {
    text-align: center;
    width: 100%;
    height: 100%;
    /* font-weight: 600; */
}
.about-d-buyers-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 4vh;
    background-color: white;
    box-sizing: border-box;
    padding: 10px 10px;
    margin-top: 1.5vh;
}
.about-d-buyers-sellers-sub-header {
    display: flex;
    padding: 10px 10px;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 1.5vh;
    background-color: white;
    margin-bottom: 1.5vh; 
}
.d-about-header {
    font-size: 3.5rem;
    width: 750px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 5px;
}
.d-about-info-container {
    width: 700px;
    /* height: 575px; */
    /* background-color: slateblue; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-about-info-top {
    font-size: 18px;
    text-align: left;

    /* background-color: slategrey; */
}
.d-about-info-middle {
    /* background-color: springgreen; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    margin: 10px 0;
}
.d-about-info-middle-item {
    font-size: 18px;
    width: 50%;
    text-align: left;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}
.d-about-gl-pic {
    height: 270px;
}
.d-about-info-middle-item2 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-about-info-bottom {
    text-align: left;
    font-size: 18px;
}
.d-about-info-signature {
    /* margin-top: 5px; */
    font-size: 25px;

    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.d-about-info-sub-signature {
    font-size: 12px;
    margin-top: 5px;
}
.d-about-news-container {
    width: 1150px;
    height: 100%;
    background-image: 
    linear-gradient(
        to right,
        rgba(255,255,255,0),
        rgba(255,255,255,0.2),
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86), 
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.86),
        rgba(255,255,255,0.2),
        rgba(255,255,255,0));    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
}
.d-about-news-header {
    font-size: 3.5rem;
    margin: 10px 0 20px;
}
.d-about-news-card-container {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

/* Legal */
.d-about-legal-container {
    width: 70%;
    padding: 15px;
    background-color: white;
}
.d-about-legal-header {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 10px;
}
.d-about-legal-title {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}
.d-about-legal-content, .d-about-legal-main-content {
    margin-bottom: 10px;
    margin-left: 10px;
}