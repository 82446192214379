.admin-auth-container-mobile{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin-left: 5vw;
    background-color: rgb(144, 191, 230);
    height: 80vh;
    margin-top: 5vh;
}
.admin-auth-form-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 25vh;
    margin-top: 10vh;
}
.sign-in-mobile {
    width: 80vw;
}