.m-ac-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(41, 87, 105);
}

.m-ac-header-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* min-height: 8vh; */
}
.m-ac-header-brand {
    min-width: 20%;
    text-align: center;
    color: white;
    font-size: 2rem;
    line-height: 0.85;
}
.m-ac-home-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-home-button {
    font-size: 1.6rem;
    padding: 4px 0;
    background-color: rgba(255, 255, 255, 0.815);
    width: 65vw;
    margin-top: 9px;
    border-radius: 15px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.m-ac-messages-update-check {
    margin-left: 7px;
    font-size: 1.3rem;
    color: red;
}
.m-ac-header-button {
    height: 100%;
    width: 100%;
    font-size: 1.8rem;
    padding: 4px 0;
    color: white;
}
.m-ac-header-item {
    height: 100%;
    width: 100%;
    color: white;
    padding: 10px 0;
    text-align: center;
}

/* ADDING LOT */

.m-ac-add-lot-container {
    width: 100%;
    min-height: 80vh;

    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;

}
.m-ac-location-item {
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 5px 0;
}
.m-ac-knollwood-category {
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: var(--primary);
    padding: 3px 0;
    /* padding-right: 5px; */
}
.m-ac-name-item {
    height: 100%;
    width: 100%;
    padding: 0 10px;
    text-align: center;
    /* padding-right: 5px; */
}
.m-ac-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-add-lot-comment {
    width: 80%;
}
.m-ac-add-comment-button {
   width: 100%;
   text-align: center; 
}
.m-ac-submit-container {
    width: 50%;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/* details */

.m-ac-details-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* view lots */

.m-ac-view-lots-container {
    height: 79vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-filter-bar-container {
    min-height: 8vh;
    /* background-color: thistle; */
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-lot-list-choose {
    width: 90%;
    text-align: center;
    margin-top: 25px;

    font-size: 1.4rem;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-lot-list-loading {
    margin-top: 20vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-lot-list-container {
    height: 71vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;

    /* background-color: thistle; */
}
.m-ac-lot-list-header {
    min-height: 4vh;
    width: 95%;

    color: white;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.m-ac-lot-list-scroll-container {
    height: 68vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-ac-lotcard-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding: 10px 0;
    background-color: rgba(255, 255, 255, 0.863);
    border-radius: 15px;
    margin-bottom: 5px;
}
.m-ac-lotcard-sharon-section, .m-ac-lot-list-header-section {
    width: 55%;
    text-align: center;
}
.m-ac-lotcard-number, .m-ac-lot-list-header-number {
    width: 45%;
    text-align: center;
}
.m-ac-lotcard-number, .m-ac-lotcard-shara {
    font-size: 1.3rem;
}
.m-ac-lot-list-header-location {
    width: 100%;
    text-align: center;
}
.m-ac-lotcard-location {
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
}
.m-ac-lot-list-header-sold-location, .m-ac-lot-list-sold-location {
    width: 30%;
}
.m-ac-lot-list-header-sold-section, .m-ac-lot-list-sold-section {
    width: 40%;
}
.m-ac-lot-list-header-sold-number, .m-ac-lot-list-sold-number {
    width: 28%
}
.m-ac-lot-list-sold-location, .m-ac-lot-list-sold-section, .m-ac-lot-list-sold-number {
    font-size: 1.2rem;
}
.m-ac-filter-knollwood-item {
    font-size: 1.5rem;
    padding: 2px 10px 2px 15px;
    width: 100%;
    text-align: right;
}
.m-ac-header-knollwood-filter {
    color: white;
    text-transform: none;
    font-size: 2rem;
}
.m-ac-lot-list-sharon-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.m-ac-lot-list-orchard {
    font-size: 1.2rem;
}
.m-ac-lotcard-sharon-section {
    font-size: 1.7rem;
}

/* individual lot */

.m-ac-individual-lot-container {
    height: 100%;
    width: 100%;

    /* background-color: tomato; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-ac-individual-header {
    /* min-height: 7vh; */
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: tomato; */
}
.m-ac-individual-close-button {
    padding: 0 10px;
    font-size: 1.5rem;
}
.m-ac-details-loading-feedback {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-individual-header-brand {
    color: white;
    font-size: 2rem;
    margin-left: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.m-ac-sold-check {
    font-size: 20px;
    padding: 5px 9px;
    border-radius: 20px;
    margin-left: 7px;
    color: green;
    background-color: rgba(255, 255, 255, 0.75);
}
.m-ac-details-picture-container {
    width: 98vw;
    height: 60vw;

    /* background-color: turquoise; */
    margin-top: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-details-yes-picture, .m-ac-details-no-picture {
    height: 100%;
    width: 100%;

}
.m-ac-details-yes-picture {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-details-yes-picture-header {
    height: 30%;
    width: 98%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-details-no-picture {
    display: flex;
    justify-content: center;
    align-items: center;

    /* background-color: turquoise; */
}

/* delete picture */
.m-ac-delete-picture-warning {
    height: 100%;
    width: 100%;

    background-color: white;
    /* border-radius: 15px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-delete-picture-header {
    height: 15%;
    width: 100%;

    background-color: var(--primary);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-delete-picture-brand {
    margin-left: 5px;
    font-size: 1.5rem;
}
.m-ac-delete-picture-close-button {
    font-size: 1rem;
    padding: 4px 0;
    text-transform: none;
}
.m-ac-delete-picture-content {
    height: 50%;
    width: 70%;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}
.m-ac-delete-picture-submit-row {
    height: 25%;
    width: 80%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-delete-picture-delete-button {
    font-weight: 600;
    font-size: 1.5rem;
    color: red;
    padding: 5px 10px;
}
.m-ac-delete-picture-cancel-button {
    font-size: 1.5rem;
    padding: 5px 10px;
}
.m-ac-delete-picture-feedback-row {
    height: 10%;
    width: 100%;
}
.m-ac-details-content-container {
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(255,255,255,0.95);
}
.m-ac-edit-buttons-container {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.m-ac-save-changes-button {
    background-color: rgb(86, 228, 86);
    height: 100%;
    width: 100%;
    padding: 8px 0;
    text-transform: none;
    font-size: 1.1rem;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-cancel-changes-button {
    background-color: rgba(0,0,0,0.35);
    height: 100%;
    width: 100%;
    padding: 8px 0;
    text-transform: none;
    font-size: 1.1rem;
}
.m-ac-start-changes-button {
    height: 100%;
    width: 100%;
    min-height: 30px;
    padding: 8px 0;
    text-transform: none;
    font-size: 1.1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-interfaith-button {
    width: 100%;
}
.m-ac-interfaith-button-style {
    height: 100%;
    width: 100%;
    padding: 8px 0;
    text-transform: none;
    font-size: 1.1rem;
    background-color: rgb(218, 218, 218);
}
/* .m-ac-knollwood-category {

} */
.m-ac-knollwood-menu-items {
    width: 100%;
    padding: 3px 20px;
}
.m-ac-details-delete-lot-container {
    /* background-color: rgb(255, 0, 0); */
    width: 70%;
}
.m-ac-delete-lot-style, .m-ac-sold-button-style {
    font-size: 1.1rem;
    color: white;
    font-weight: 800;
}
.m-ac-delete-lot-style, .m-ac-sold-button-style {
    padding: 8px 0;
    height: 100%;
    width: 100%;
}
.m-ac-sold-button-style {
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-details-date-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 10px;

}
.m-ac-details-date-header {
    font-size: 1.2rem;
}
.m-ac-details-date {
    font-size: 1.5rem;
    margin-top: 5px;
}

/* Messages */

.m-ac-messages-container, .m-ac-testimonials-container, .m-ac-news-container {
    height: 79vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-delete-messages-button {
    height: 100%;
    width: 100%;

    background-color: red;
    color: white;

    font-size: 1.5rem;
    padding: 3px 0;
}
.m-ac-all-messages-container {
    height: 70vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
    margin-bottom: 10px;
}
.m-ac-message-card-container {
    /* min-height: 20vh; */
    padding: 15px 0;
    flex-shrink: 0;
    width: 95%;
    background-color: rgba(255, 255, 255, 0.788);
    margin-bottom: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-message-card-body {
    width: 95%;
    /* height: 95%; */
    /* background-color: tomato; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.3rem;
    line-height: 0.98;

}
.m-ac-message-card-bottom {
    width: 90%;
    height: 95%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-delete-single-message-button {
    color: white;
    padding: 5px 0;
    min-height: 25px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-no-messages-notification {
    margin-top: 30px;
    font-size: 1.5rem;
    color: white;
    text-align: center;
}
.m-ac-delete-all-messages-warning {
    height: 100%;
    width: 100%;

    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* Testimonials */

.m-ac-testimonials-header-container {
    min-height: 9vh;
    width: 98%;

    /* background-color: thistle; */

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-testimonials-header-brand {
    color: white;
    font-size: 1.4rem;
    margin-left: 8px;

    line-height: 1.1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.m-ac-testimonial-button {
    line-height: 1;
    padding: 3px 8px;
}
.m-ac-testimonials-all-container {
    height: 70vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;

}
.m-ac-testimonials-none {
    margin-top: 30px;
    color: white;
    width: 90%;
    text-align: center;
}
.m-ac-testimonial-card-container {
    width: 95%;

    background-color: rgba(255, 255, 255, 0.788);
    margin-bottom: 7px;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 15px 0;
}
.m-ac-testimonial-card-body {
    width: 90%;
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.3rem;
    word-wrap: break-word;
    line-height: 1;
}
.m-ac-testimonial-card-info {
    width: 90%;
    margin-bottom: 8px;
    /* font-size: 1.4rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* font-weight: 600; */
}
.m-ac-testimonial-card-options {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-testimonial-card-button {
    padding: 3px 7px;
    font-size: 1.1rem;
    min-height: 36px;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-ac-testimonial-card-name {
    width: 40%;
}
.m-ac-testimonial-card-date {
    width: 55%;
}
.m-ac-testimonials-save-buton {
    height: 100%;
    width: 100%;

    padding: 8px 0;
    background-color: rgb(86, 228, 86);
    min-height: 30px;

    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* News */

.m-ac-news-header {
    width: 100%;
    min-height: 7vh;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-news-brand {
    font-size: 2rem;
    margin-left: 15px;
    color: white;
    /* font-weight: 600; */
}
.m-ac-news-backdrop-container {
    height: 100%;
    width: 100%;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-news-backdrop-header, .m-ac-news-delete-backdrop-header {
    height: 15%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: var(--primary);
}
.m-ac-news-backdrop-brand, .m-ac-news-delete-backdrop-brand {
    margin-left: 10px;
    font-size: 1.5rem;
}
.m-ac-news-backdrop-body {
    height: 70%;
    width: 85%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-ac-news-backdrop-submit {
    height: 15%;
    width: 85%;
    /* background-color: thistle; */
}
.m-ac-news-submit-style {
    height: 100%;
    width: 100%;
    /* padding: 8px 0; */
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(86, 228, 86);
    text-transform: none;
    font-size: 1.2rem;
    min-height: 33px;
}

.m-ac-all-news-container {
    height: 71vh;
    width: 100%;

    /* background-color: thistle; */

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;

    /* margin-bottom: 15px; */
}
.m-ac-news-card-container {
    /* min-height: 50px; */
    width: 95%;
    margin-top: 7px;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
}
.m-ac-news-card-date {
    margin-bottom: 15px;
    font-size: 0.9rem;
    color: #8a8f90;
}
.m-ac-news-card-options {
    width: 95%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.m-ac-news-card-option {
    width: 35vw;
    height: 100%;
    padding: 5px;

}
.m-ac-news-card-delete {
    background-color: red;
    color: white;
    border-radius: 4px;
}
.m-ac-news-save-button {
    height: 100%;
    width: 100%;

    background-color: rgb(86, 228, 86);

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 33px;
}

.m-ac-news-delete-backdrop-container {
    height: 100%;
    width: 100%;
    
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


}
.m-ac-news-delete-backdrop-body {
    height: 70%;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-ac-news-delete-backdrop-options {
    height: 15%;
    width: 80%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-ac-news-delete-title {
    font-weight: 600;
    margin-top: 10px;
    font-size: 1.2rem;
}
.m-ac-news-delete-button {
    background-color: red;
    color: white;
    padding: 5px 10px;
    min-height: 30px;
    min-width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}
.m-ac-news-cancel-button {
    padding: 5px 10px;
    min-height: 30px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

/* gallery */

.m-ac-gallery-container {
    min-height: 79vh;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-ac-gallery-card-container {
    width: 95%;
    padding: 8px 0;

    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    border-radius: 7px;
}
.m-ac-gallery-card-image {
    height: 50vw;
    width: 95%;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.m-ac-gallery-save-container {
    height: 33px;
    width: 70vw;

    background-color: rgb(86, 228, 86);
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
}