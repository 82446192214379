.contact-container {
    height: 91vh;
    width: 1050px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.contact-d-container {
    margin-top: 5vh;
}
.contact-details-container {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0 2vw;
}
.contact-details-section {
    margin: 1.5vh 0;
    width: 100%;
    font-size: 3vh;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.testimonial-d-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.945);
    margin-top: 5vh;
    padding-top: 2vh;
}
.testimonial-d-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2vh;
}
.testimonial-d-button-question {
    color: black;
    margin-bottom: 1vh;
    font-size: 3vh;
}
.contact-d-testimonial-form {
    padding-bottom: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 35vh;
}
.contact-d-testimonial-message {
    width: 35vw;
}
.contact-d-testimonials-top {
    width: 35vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.contact-d-submit-message {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4vh;
    padding-bottom: 2vh;
}
.d-link-fix {
    text-decoration: none;
    color: black;
}