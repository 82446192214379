.context-lot-filter-container {
    width: 100%;
    background-color: skyblue;
    height: 7vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
/* .new-admin-d-lot-content-container */
.context-lot-filter-location {
    /* font-size: 1.3rem; */
    /* background-color: steelblue; */
    height: 80%;
    /* width: 50%; */
    margin: 0 5%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.context-lot-filter-font {
    font-size: 1.6rem;
    font-weight: 600;
}
.context-lot-filter-location-item {
    font-size: 1.6rem;
    /* font-weight: 600; */
}
.context-lot-filter-name {
    /* margin-: 2%; */
    height: 80%;
    /* width: 32%; */
    /* background-color: tomato;sss */

    display: flex;
    justify-content: center;
    align-items: center;
}
.context-lot-filter-section {
    margin-left: 2%;
    height: 80%;
    width: 10%;
    /* background-color: violet; */

    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-loading-lots-progress-container {
    height: 1.5vh;
    width: 100%;
    /* background-color: slateblue; */
}
.container-d-lots {
    width: 100%;
    /* height: 100%; 
    */
    overflow: scroll;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.container-d-lotcard {
    /* height: 7vh; */
    width: 98%;
    background-color: rgba(86, 190, 231, 0.753);
    margin-bottom: 1vh;
    color: black;
}
/* new-admin-d-lot-content-container */
.container-context-sharon-card, .container-context-knollwood-card, .container-context-estate-card {
    height: 100%;
    width: 93%;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.container-context-section {
    margin-left: 2%;
}
/* .context-lot-filter-container */
.context-d-details-header {
    height: 4vh;
    width: 90%;
    font-size: 1rem;
    padding: 6px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.context-d-details-header-sold {
    height: 4vh;
    width: 78.4%;
    font-size: 1rem;
    padding: 6px 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.container-context-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23%;
}
.container-context-item2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}
.container-context-font {
    font-size: 1.6rem;
    font-weight: 600;
    color: black;
}