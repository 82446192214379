.d-u-admin-container {
    /* background-color: steelblue; */
    width: 1050px;
    height: 91vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-login-container {
    width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(255,255,255,0.6);
    border-radius: 15px;
}
.d-login-error-container {
    min-height: 20px;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 20px;
    /* width: 400px; */
    display: flex;
}
.d-login-error-message {
    font-weight: 600;
    color: red;
}
.d-login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}