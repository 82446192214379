.practice-container {
    width: 100%;
    height: 50vh;
    background-color: thistle;

    display: flex;
    justify-content: center;
    align-items: center;
}

.test-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}