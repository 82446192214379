.gallery-d-gridlist-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
    height: 400px;
}
.gridlist-d-sharon-image {
    height: 500px;
    width: 700px;
}
.gallery-d-location-container {
    /* height: 50vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97%;
    /* height: 440px; */
    /* margin-top: 1vh; */
}
.gallery-d-tab-container {
    width: 1050px;
    height: 91vh;
    display: flex;
    justify-content: flex-start;
    /* padding-top: 10px; */
    align-items: center;
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.815);
     */
    background-color: rgba(255, 255, 255, 0.849);
    /* padding-top: 15px; */
}
.gallery-tab-container {
    height: 100%;
    width: 100%;
}
.d-gallery-tab-header {
    color: black;
    font-size: 3.5rem;
    font-weight: 600;
    margin: 5px 0 10px;
}
.d-gallery-sub-header {
    color: black;
    font-size: 1.3rem;
    margin-top: 8px;
}
.d-new-gallery-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    height: 100%;
    overflow-y:scroll;
}
.d-new-gallery-content-update {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: scroll;
}
.d-gallery-updated-container {
    height: 600px;
    width: 98%;
    display: block;
    /* flex-direction: column;
    align-items: center; */

    overflow: scroll;

}
.d-gallery-updated-item {
    /* padding: 280px 470px; */
    margin-bottom: 10px;
}