/* addLotFix */

.admin-d-testimonials-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-d-testimonials-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pending-d-container {
    height: 100%;
    margin-top: 2vh;;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}
.pending-d-card-container {
    width: 50vw;
    height: 100%;
    border: 1px solid black;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: white;
}

.pending-d-card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    align-items: stretch;
    width: 68%;
    height: 100%;
}
.pending-d-card-header {
    /* width: 100%; */
    /* height: 100%; */
    background-color: skyblue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.pending-d-card-body {
    height: 100%;
    width: 100%;
    align-items: stretch;
    background-color: white;
}
.pending-d-card-header-name {
    margin-left: 1vw;
    font-size: 1.6rem;
    padding: 1vh 0;
}
.pending-d-card-header-date {
    margin-right: 1vw;
}
.pending-d-card-message {
    margin: 1vh;
}
.pending-d-card-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-self: center; */
    align-items: stretch;
    height: 100%;
    width: 32%;
}
.pending-d-card-publish, .pending-d-card-edit, .pending-d-card-delete {
    text-align: center;
    margin-top: 1vh;
}
.pending-d-card-delete {
    padding-bottom: 2vh;
}
.admin-d-testimonials-backdrop-container {
    z-index: inherit;
    height: 20vh;
    width: 40vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.admin-d-testimonials-backdrop-options{
    z-index: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 2vh;
}
.admin-d-testimonials-backdrop-item {
    z-index: inherit;
    color: black;
}
.admin-d-testimonials-backdrop-question {
    color: black;
    padding-top: 5%;
    width: 80%;
}
.admin-d-testimonials-successful-action {
    display: flex;
    margin: auto;
    padding: 2vh 0;
}
.admin-d-testimonials-control-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50vw;
}
.admin-d-testimonials-control-item {
    font-size: 2rem;
    color: white;
}
.pending-d-card-save {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
}

/* .admin-d-gallery-item admin-destop-option-icon admin-desktop-home-item home padasdge desktop-admin-status admin-desktop-home-container */


.new-admin-d-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
    width: 100%;
}
.new-admin-d-lot-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    /* margin-top: 2vh; */
}
.new-admin-d-lot-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    width: 100%;
}
.new-admin-d-add-lot, .new-admin-d-lot-brand {
    padding: 0 2vw;
}
.new-admin-d-lot-brand {
    font-size: 2rem;
    color: white;
}
.new-admin-d-lot-content-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 90%;
    background-color: white;
    /* padding-top: 15px; */
}
.new-admin-d-add-lot-logo {
    color: white;
    font-size: 1.2rem;
    margin-left: 0.5vw;
}
.new-admin-d-add-lot-backdrop {
    height: 70%;
    width: 50vw;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.new-admin-d-add-lot-backdrop-pic-container {
    height: 25%;
    width: 100%;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;

}
/* new-admin-d-lot-submit-row2 */
.new-admin-d-add-lot-backdrop-header {
    height: 15%;
    width: 100%;
    background-color: skyblue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.new-admin-d-add-lot-backdrop-header-item{
    margin: 0 2vw;
    color: black;
    font-size: 2rem;
}
.new-admin-d-add-lot-backdrop-body {
    height: 65%;
    width: 100%;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.new-admin-d-add-lot-backdrop-location {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    padding-top: 1vh;
    font-size: 2rem;
}
.new-admin-d-location-choice {
    font-size: 1.3rem;
}
.new-admin-d-location-sharon-form {
    height: 100%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2vh;
}
.new-admin-d-add-lot-backdrop-form-container {
    width: 100%;
    height: 100%;
}
.new-admin-d-location-sharon-form-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20%;
    justify-content: space-evenly;
}
.new-admin-d-lot-submit-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}
.new-admin-d-lot-submit-row2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}
.new-admin-d-lot-submit-row-item {
    /* new-admin-d-lot-header */
}
.new-admin-d-location-sharon-form-comment {
    width: 60%;
    min-height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
    /* margin-top: 1vh; 50% */
}
.new-admin-create-lot-picture-spot {
    height: 100%;
    width: 100%;
}
.new-admin-d-add-lot-backdrop-location-header {
    color: black;
}
.new-admin-add-comment-button {
    color: black;
}

.new-admin-new-lot-success-container {
    height: 50vh;
    width: 50vw;
    background-color: skyblue;
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-admin-d-location-knollwood-form {
    color: black;
    height: 100%;
    width: 100%;
    /* background-color: white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* padding-top: 2vh; */
    /* background-color: slategray; */
}
/* admin-final-d-lot-card-form-row */
.new-admin-knollwood-d-options-container {
    background-color: slateblue;
}
.new-admin-d-add-lot-backdrop-name {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    font-size: 2rem;

}
.new-admin-d-add-lot-backdrop-name2 {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;

}
.new-admin-d-knollwood-category {
    width: 12vw;
    text-align: center;
    font-weight: 800;
    font-size: 1rem;
    /* padding-right: 2vw; */
    /* background-color: skyblue; */
    padding: 5px 0;
    align-self: right;
}

.new-admin-d-location-knollwood-form-row   {
    height: 25%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
/* new-admin-d-location-estate-form-row1 */
.admin-d-new-estate-form-container {
    z-index: 180000000;
    /* background-color: yellow; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.new-admin-d-location-estate-form-row {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 1.5vh;
}
/* new-admin-d-location-estate-form-row  .admin-d-new-estate-form-container new-admin-d-add-lot-backdrop-header */
.new-admin-d-location-estate-form-comment {
    height: 20%;
    width: 70%;
    /* margin-left: 15%; */
    margin-bottom: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: springgreen; */
}
.new-admin-d-location-estate-form-location {
    font-size: 1.6rem;
    color: black;
    margin: 2vw;
}
.new-admin-d-location-estate-form-item {
    width: 30%;
}
.new-admin-d-location-estate-form-row1 {
    width: 50%;
    height: 15%;
    /* margin-left: 25%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5vh;
}
.admin-d-successful-creation-container {
    height: 100%;
    width: 100%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.admin-d-successful-creation-header {
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(135, 207, 235, 0.541);
}
.admin-d-successful-creation-header-item {
    font-size: 1.4rem;
    padding: 0 1vw;
}
.admin-d-successful-creation-body {
    height: 70%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.admin-d-successful-creation-body-details {
    width: 58%;
    height: 100%;
    background-color: white;
    
}
.admin-d-successful-creation-body-image {
    width: 38%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-d-successful-creation-body-divider {
    width: 2%;
    height: 90%;
    border-radius: 1vh;
    background-color: rgba(135, 207, 235, 0.541);
}
.admin-d-successful-creation-body-details-row1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.admin-d-successful-creation-body-details-row1-item {
    padding: 0 1vh;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: tomato; */
}
.admin-d-successful-creation-body-details-row {
    width: 100%;
    height: 20%;
    /* background-color: slateblue; */
    margin-top: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.admin-d-successful-creation-body-details-row-item {
    /* height: 100%; */
    padding: 1vh;
    text-align: center;
}
.admin-d-successful-creation-sold-container {
    display: flex;
    justify-content: center;
    align-items: center;
}