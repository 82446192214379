.t-gallery-container {
    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-gallery-options {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    /* width: 750px; */
    /* margin-top: 5px; */
}
.t-gallery-options-item {
    font-size: 1.8rem;
    /* font-weight: 600; */
    color: rgba(255, 255, 255, 0.788);
    line-height: 0.92;
    /* padding: 5px 0; */
    text-transform: none;
}
.t-gallery-header {
    width: 100%;
    height: 5vh;
    text-align: center;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-gallery-content {
    width: 98%;
    height: 78vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;

}