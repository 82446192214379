.m-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary);
    width: 100vw;
    min-height: 8vh;
}
.m-nav-brand-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
}
.m-nav-logo {
    height: 42px;
}
.m-nav-brand {
    color: black;
    font-size: 28px;
}
.m-nav-item {
    color: black;
    font-size: 2rem;
}

.m-footer-container {
    /* position: sticky; */
    /* bottom: 0; */
    z-index: 3000;
    background-color: var(--secondary);
    min-height: 5vh;
    /* padding: 5px 0 2px; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-footer-item {
    /* margin: 0 10px; */
    color: var(--primary-dark);
    font-size: 0.78rem;
}
.m-footer-link {
    color: var(--primary-dark);
    text-decoration: none;
}