.d-news-container {
    width: 70vw;
    height: 80vh;
    margin-top: 3vh;
    margin-left: 15vw;
    background-image: 
        linear-gradient(
            to right,
            rgba(255,255,255,0),
            rgba(255,255,255,0.2),
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.2),
            rgba(255,255,255,0));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-news-header {
    margin-top: 3vh;
    font-size: 2.5rem;


}
.d-news-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

}
.d-news-card-container {
    /* min-height: 30vh; */
    padding: 2vh 0;
    width: 80%;
    margin-top: 2vh;
    background-color: rgba(135, 207, 235, 0.418);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-news-card-date {
    font-size: 0.8rem;
    color: rgb(66, 125, 148);
}
.d-news-card-title {
    font-size: 2.1rem;
}
.d-news-card-content {
    font-size: 1.1rem;
    width: 80%;

    text-align: center;
}