.t-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary);
    width: 100vw;
    height: 7vh;
}
.t-nav-brand-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
}
.t-nav-logo {
    height: 65px;
}
.t-nav-brand {
    color: black;
    font-size: 50px;
}
.t-nav-item {
    color: black;
    font-size: 2.5rem;
}
.t-footer-container {
    height: 3vh;
    width: 100vw;
    background-color: var(--secondary);
    z-index: 200;
}
.t-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.t-footer-item {
    margin: 0 10px;
    color: var(--primary-dark);
}
.t-footer-link {
    color: var(--primary-dark);
}