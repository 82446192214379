.d-footer-container {
    position: fixed;
    bottom: 0;
    width: 1150px;
    /* left: 7vw; */
    display: flex;
    background-color: rgba(150, 208, 231, 0.849);
    justify-content: center;
    align-items: center;
    height: 4vh;
    border-radius: 25px 25px 0 0;
    color: rgba(20, 47, 56, 0.6);
    /* padding-top: 4px; */
}
.d-footer-link {
    text-decoration: none;
    color: rgba(20, 47, 56, 0.6);
    margin-left: 0.1vw;
}
.d-footer-item {
    margin: 0 10px;
}