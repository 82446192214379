.home-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 90vw; */
    /* margin-left: 5vw; */
    /* margin-top: 1.5vh; */
}
.admin-auth-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* width: 80vw; */
    /* margin-left: 10vw; */
    /* height: 40vh; */
    margin-top: 5vh;
}
.admin-auth-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70vw;
    height: 25vh;
    margin-top: 10vh;
}
.desktop-login {
    width: 40vw;
}
.testlog {
    font-size: 10vh;
}

.login-container{
    margin-top: 1vh;
}
/* .home-d-guest-container

admin-d-option-fix admin-desktop-option-header addLotFix admin-desktop-home-item admin-desktop-option-header a */

.admin-destop-option-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-add-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
}
/* desktop-admin-status new-admin-d-lot-submit-row2 */
.desktop-add-lot-item {
    width: 8vw;
    /* margin: 1vh; */
}
.add-lot-fix {
    /* margin-left: 1vw; */
    display: flex;
    justify-content: center;
}
.d-add-second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.d-add-second-options {
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: space-between;
    margin-right: 3vw;;
}
.d-add-second-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.d-add-second-comment-submit {
    margin-top: 2vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: row;
}
.desktop-add-lot-comment {
    width: 20vw;
    height: 150%;
}
.d-add-second-header {
    margin-left: 1vw;
    margin-bottom: 1vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-add-header-location {
    font-size: 5vh;
}
.d-add-header-action {
    font-size: 2.5vh;
}
.desktop-add-lot-final {
    /* width: 30vw; */
    padding-left: 1vw;
}
.d-add-first {
    margin-left: 1vw;
    margin-right: 1vw;
}
.desktop-create {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5.5vh;
    width: 9.4vw;
    font-size: 2.5vh;
}
.desktop-add-success {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vh;
}

.home-d-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* guest admin-desktop-home-container*/

.home-d-guest-container {
    background-image: 
        linear-gradient(
            to right,
            rgba(255,255,255,0),
            rgba(255,255,255,0.2),
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86), 
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.86),
            rgba(255,255,255,0.2),
            rgba(255,255,255,0));
    width: 1150px;
    /* margin-left: 7vw; */
    /* height: 90vh; */
    /* background-color: rgba(255,255,255,0.5); */
    /* padding-top: 1.5vh; */
    /* margin-top: 50.5vh; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 91vh;
    overflow: scroll;
    /* transition
    height
    home-d-guest-sub-gallery
    : 1s; */
}

.home-d-gallery-container {
    /* background-color: white; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 370px;
    /* width: 55vw; */
}
.home-d-loading-gallery {
    height: 370px;
}
.d-gallery-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.d-gallery-left, .d-gallery-right {
    background-color: tomato;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.d-gallery-left {
    padding-right: 3vw;
}
.d-gallery-right {
    padding-left: 3vw;
}
/* .newcard-d-comments */
.admin-d-gallery-container {
    /* height: 67.5vh; */
    /* width: 40vw;  */
    /* height: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: scroll;
    margin-bottom: 3vh;
}
.admin-d-gallery-container::-webkit-scrollbar {
    display: none;
  }
.admin-d-gallery-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5vh;
    text-align: center;
    width: 100%;
    /* height: 50vh; */
    background-color: white;
}
.admin-d-gallery-photo {
    margin: 1vh;
    height: 30vh;
    width: 30vh;
    background-color: green;
    display: flex;
    flex-direction: column;
}
.admin-d-gallery-upload-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0.5vh;
}
.admin-d-gallery-upload-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-d-gallery-card-info {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.admin-d-gallery-input-header {
    margin-top: 1.5vh;
}
.admin-d-gallery-input-header, .admin-d-gallery-input-body {
    width: 90%;
}
.admin-d-gallery-input-body {
    height: 50%;
    margin-top: 1.5vh;
}
.home-d-guest-gallery-previous {
    margin-right: -6.5vw;
    /* height: 100%; */
    /* background-color: silver; */
    z-index: 500;
    height: 55vh;
}
.home-d-navigation-button {
    /* margin: auto; */
    /* margin-top: 25%; */
    text-align: center;
    height: 50%;
    background-color: rgba(255,255,255,0.3);
}
.home-d-guest-gallery-next {
    margin-left: -6.5vw;
    /* height: 100%; */
    z-index: 500;
    height: 55vh;
}
.home-d-guest-gallery-navigator{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vw;
    /* height: 5vh; */
}
.d-gallery-header-container {
    /* background-color: rgba(255,255,255,0.8); */
    color: black;
    font-size: 2vh;
    width: 100%;
}
.d-gallery-gradient-top {
    height: 4vh;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.8), rgba(255,255,255,0));
}
.d-gallery-caption {
    width:60%;
    font-size: 1.4vh;
    margin-bottom: 1vh;;
}
.d-gallery-header {
    background-color: rgba(255,255,255,0.8);
}
.d-gallery-gradient-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.d-gallery-gradient-bottom {
    height: 4vh;
    width: 100%;
    background-image: linear-gradient(to top, rgba(255,255,255,0.8), rgba(255,255,255,0));
}
/* home-d-guest-container */
.d-gallery-caption-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(255,255,255,0.8);
}
.filler-test {
    background-color: rgba(255,255,255,0.8);
    height: 4vh;
}
.home-d-slider-container {
    /* transition: 1s; */
    width: 75%;
    margin-bottom: 2.5vh;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.admin-knollwood-header {
    margin-right: 0.5vw;;
}
.admin-knollwood-names {
    background-color: rgba(255,255,255,0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-radius: 0 0 2vh 2vh; */
    /* padding: 2vh */
    width: 60vw;
    padding-bottom: 2vh;
}
.home-d-guest-sub-gallery {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin-bottom: 20px;
    /* background-color: teal; */
}
.home-d-guest-header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 0 7px;
}
.home-d-guest-header-name {
    font-size: 72px;
    font-weight: 800;
    margin: 10px 0 8px;
    /* margin-bottom: 10px; */
}
.home-d-guest-header-contact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.home-d-guest-header-contact-item {
    color: black;
    font-size: 1.4rem;
    margin: 0 12px;
    font-weight: 600;
}
.home-d-guest-header-statement {
    color: rgb(42, 98, 124);
    font-size: 1.9rem;
    font-weight: 600;
    /* margin: 0.4vh 0 0.8vh; */
}
.home-d-guest-sub-header {
    margin-top: 1vh;
    text-align: center;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.home-d-guest-sub-header2 {
    margin-top: 10px;
    font-size: 0.8rem;
    font-weight: 800;
}
.home-d-guest-sub-header-info {
    /* font-size: 1.2rem; */
    line-height: 0.9;
}
.home-d-guest-sub-header-body {
    width: 95%;
    text-align: center;
    margin: 13px 0;
    font-size: 1.1rem;
    line-height: 1;
    /* margin-bottom: 20px; */
}

.home-d-gallery-center, .home-d-testimonials-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-d-gallery-center {
    width: 90%;
}
.home-d-new-testimonials-container {
    width: 70%;
    height: 40vh;
    margin: 2vh 0 10vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.home-d-testimonials-header {
    height: 20%;

    font-weight: 600;
    font-size: 1.5rem;
    /* color: skyblue; */
}
.home-d-new-testimonials-content {
    width: 100%;
    height: 80%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.home-d-testimonials-left {
    height: 100%;
    width: 75%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-d-testimonials-right {
    height: 100%;
    width: 25%;
    margin: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}
.home-d-testimonials-cards-container {
    height: 40vh;
    width: 100%;
    /* background-color: yellow; */
    /* background-color: slateblue; */
}
.home-d-testimonials-card-container {
    /* background-color: rgb(129, 200, 228); */
    height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}


.home-d-testimonials-card-name {
    padding-top: 0.5vh;
    font-size: 1.6rem;
    /* height: 20%; */
    padding: 1vh 0;
    background-image: 
        linear-gradient(
            to right,
            rgba(129, 200, 228,0),
            rgba(129, 200, 228,0.3),
            rgba(129, 200, 228,0.8),
            rgba(129, 200, 228,0.8),
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8), 
            rgba(129, 200, 228,0.8),
            rgba(129, 200, 228,0.8),
            rgba(129, 200, 228,0.3),
            rgba(129, 200, 228,0));
    width: 90%;
    text-align: center;
}
.home-d-testimonials-card-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60%;
    width: 90%;
    text-align: center;
    /* margin: 2vh; */
    font-size: 1.3rem;
    /* padding: 3vh 3vh 0; */
    background-image: 
        linear-gradient(
            to right,
            rgba(255,255,255,0),
            rgba(255,255,255,0.3),
            rgba(255,255,255,0.8),
            rgba(255,255,255,0.8),
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8), 
            rgba(255,255,255,0.8),
            rgba(255,255,255,0.8),
            rgba(255,255,255,0.3),
            rgba(255,255,255,0));
    padding-top: 1vh;
}