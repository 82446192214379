.m-directors-container {
    width: 100vw;
    height: 87vh;
    background-color: var(--mobile-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-directors-options {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
}
.m-directors-options-item {
    font-size: 1.05rem;
    /* font-weight: 600; */
    color: rgba(255, 255, 255, 0.788);
    line-height: 0.92;
    /* padding: 5px 0; */
    text-transform: none;
}
.m-directors-header {
   height: 5vh;
   width: 100%;
   
   font-size: 1.6rem;
   display: flex;
   justify-content: center;
   align-items: center;
}
.m-directors-location {
    width: 100%;
    height: 4vh;
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-directors-location-header {
    height: 3.5vh;
    width: 98%;
    background-color: var(--primary);

    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}
.m-directors-header-item {
    /* height: 100%; */
    /* width: 24%; */
    font-size: 0.9rem;
    text-align: center;
}
.m-directors-header-section {
    width: 35%;
    font-size: 0.9rem;
    text-align: center;
}
.m-directors-header-number {
    width: 17%;
    font-size: 0.9rem;
    text-align: center;
}
.m-directors-header-price {
    width: 31%;
    font-size: 0.9rem;
    text-align: center;
}
.m-directors-content {
    height: 66.5vh;
    width: 98%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    background-color: var(--primary);
}

/* cards */

.m-directors-card-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 99%;
    background-color: white;
    margin: 2px 0;
    padding: 6px 0;
}
.m-directors-card-container-updated {
    width: 99%;
    display: flex;
    flex-direction: row; 
    min-height: 10vh;
    padding: 5px 0;
    flex-shrink: 0;
    margin-bottom: 5px;
    background-color: white;
}
.m-directors-card-section-updated {
    width: 24%;
    height: 100%;
    /* padding: 5px 0; */
    /* background-color: turquoise; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.m-directors-card-section-knollwood {
    width: 30%;
    height: 100%;
    /* padding: 5px 0; */
    /* background-color: turquoise; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.m-directors-card-middle {
    width: 46%;
}
.m-directors-card-middle-knollwood {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-directors-card-right {
    width: 30%
}
.m-directors-card-middle, .m-directors-card-right {
    /* width: 35%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* justify-content: space-between; */
    align-items: center;
}
.m-directors-card-middle-item {
    width: 100%;
    padding: 2px 0;
    /* height: 50%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
.m-directors-card-section-header, .m-directors-card-middle-label, .m-directors-card-right-price-label {
    font-size: 0.7rem;
}
.m-directors-card-section-content {
    font-size: 1.7rem;
    padding-top: 5px;
    text-align: center;
}
.m-directors-card-name-content {
    font-size: 0.97rem;
    padding-top: 5px;
    text-align: center;
}
.m-directors-card-middle-content {
    font-size: 0.93rem;
    /* font-weight: 600; */
    /* margin-left: 8px; */
}
.m-directors-card-right-price {
    font-size: 1.3rem;
    /* margin-top: 8px; */
    /* margin-left: 12px; */
    text-align: center;
    /* font-weight: 600; */
}
.m-directors-card-container-updated:hover {
    background-color: rgba(255,255,255,0.8);
    /* color: white; */
    /* font-weight: 600; */
}
.m-directors-section-card-fix {
    font-size: 0.9rem;
}
.m-directors-loading-center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.m-directors-beersheba-fix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
}
.m-directors-beersheba-header {
    font-size: 0.8rem;
}
.m-directors-beersheba-number {
    font-size: 1.5rem;
}