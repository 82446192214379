.d-inventory-container {
    width: 1050px;
    height: 91vh;
    /* background-color: rgba(255, 255, 255, 0.767); */
    /* border-radius: 1vh; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    
}
.d-inventory-choose-location-container {
    padding-top: 5%;
    height: 91vh;
    width: 1050px;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-inventory-choose-location-header {
    font-size: 1.8rem;
    margin: 20px 0 15px;
}
.button-group-spacing {
    margin-top: 2vh;
}
.d-inventory-choose-item {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 0.3vw;
}
.d-inventory-initial-spinner {
    margin-top: 10vh;
}
.d-inventory-loaded-container {
    height: 100%;
    width: 100%;
    border-radius: 1vh;

    display: flex;
    flex-direction: row;
}
.d-inventory-left {
    height: 100%;
    width: 20%;
    background-color: rgba(255, 255, 255, 0.85);


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-inventory-right {
    height: 100%;
    width: 80%;
    /* background-color: rgba(255, 255, 255, 0.6); */

    display: flex;
    justify-content: center;
    align-items: center;

}
.d-inventory-current-location {
    font-size: 1.5rem;
}

.d-inventory-filter-location-container {
    margin-top: 4%;
}
.d-inventory-second-filter-container {
    width: 100%;
    margin-top: 1vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-inventory-lotview-container {
    /* background-color: springgreen; */
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
}
.d-inventory-lotview-header {
    height: 30px;
    width: 100%;

    /* background-color: teal; */
}
.d-inventory-lotview-lots {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    /* background-color: yellow; */
    overflow: scroll;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-inventory-lotview-lots::-webkit-scrollbar {
    display: none;
  }
.d-inventory-lotview-footer {
    height: 20px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: cyan; */
}
.d-inventory-header-container {
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.d-inventory-header-item {
    font-weight: 600;
    font-size: 1.4rem;
    color: black;
    /* outline: 2px solid black; */
    

    width: 20%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-inventory-header-sold-item {
    font-weight: 600;
    font-size: 1.1rem;

    width: 13%;

    display: flex;
    justify-content: center;
    align-items: center;
}


.d-inventory-card-container {
    width: 100%;
    height: 100%;
    background-color: var(--primary);

    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.d-inventory-card-item {
    width: 20%;
    font-size: 1.13rem;
    color: black;
}
/* .d-inventory-card-item {
    width: 20%;
    font-size: 1.4rem;
    color: black;
} */
.d-inventory-card-sold-item {
    width: 12%;
    font-size: 1rem;
    color: black;
}
.d-inventory-backdrop-container {
    height: 640px;
    width:  850px;

    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.d-i-b-header {
    width: 100%;
    height: 8%;
    background-color: skyblue;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-i-b-header-item {
    padding: 0 1vw;
    font-size: 2rem;
}
.d-i-b-gallery {
    width: 100%;
    height: 45%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-i-b-gallery-pic {
    height: 95%;
    width: 60%;
}
.d-i-b-details {
    width: 100%;
    height: 13%;
}
.d-i-b-item {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.d-i-b-details-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.d-i-b-label {
    height: 30%;
    font-size: 0.85rem;
}
.d-i-b-info {
    height: 70%;
    font-size: 1.8rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-i-b-info2 {
    height: 70%;
    font-size: 1.3rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-i-b-info3 {
    height: 70%;
    font-size: 1.1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-i-b-interfaith {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 1.2rem;
}
.d-i-b-footer {
    width: 100%;
    height: 35%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.d-i-b-comments-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 40%; */
    width: 100%
}
.d-i-b-comments-header {
    margin-bottom: 7px;
    font-size: 0.95rem;
}
.d-i-b-comments-info {
    width: 600px;
    font-size: 1.3rem;
    text-align: center;
}
.d-i-b-footer-content {
    background-color: skyblue;
    /* padding-top: 1vh;
    padding-bottom: 1vh; */
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.d-i-b-footer-or {
    font-size: 1.3rem;
    padding: 10px 0;
}
.d-i-b-message-container {
    height: 220px;
    width: 400px;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.d-i-b-message-header {
    background-color: skyblue;
    height: 25%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-i-b-message-header-item {
    padding: 0 1vw;
    font-size: 1.5rem;
}
.d-i-b-message-body {
    width: 95%;
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-i-b-message-footer {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.d-i-b-message-footer-container {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-i-b-message-success {
    margin-top: 3%;
    width: 80%;
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
}
.d-i-b-message-container2 {
    height: 30vh;
    width: 50vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}