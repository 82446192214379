.d-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(150, 208, 231, 0.849);
    justify-content: space-between;
    width: 1150px;
    position: sticky;
    top: 0;
    /* left: 7vw; */
    border-radius: 0 0 15px 15px;
    z-index: 1000;
    height: 5vh;
}
.d-nav-header {
    margin-left: 10px;
}
.d-nav-brand-container {
    display: flex;
    flex-direction: row;
    font-size: 1.8rem;
    transition: 0.4s;
}
.d-nav-logo {
    height: 40px;
}
.d-nav-brand {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-nav-links {
    display: flex;
    flex-direction: row;
    height: 100%;
}
.d-nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    height: 100%;
    /* width: 6vw; */
    padding: 0 10px;
    color: rgb(20, 47, 56);
    transition: 0.4s;
}
.d-nav-link:hover {
    background-color: rgba(195, 225, 235, 0.897);
    /* color: skyblue; */
}
.d-nav-link-last {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    height: 100%;
    /* width: 6vw; */
    padding: 0 10px;
    color: rgb(20, 47, 56);
    transition: 0.4s;
    border-radius: 0 0 15px 0;
}
.d-nav-link-last:hover {
    background-color: rgba(195, 225, 235, 0.897);
    /* color: skyblue; */
}
.d-nav-link-directors {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    height: 100%;
    padding: 0 10px;
    /* width: 1vw; */
    color: rgb(20, 47, 56);
    transition: 0.4s;
}
.d-nav-link-directors:hover {
    background-color: rgba(195, 225, 235, 0.897);
    /* color: skyblue; */
}