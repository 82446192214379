.t-home-container {
    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.t-home-scroll-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow: scroll;
}
.t-home-brand {
    font-size: 5rem;
    margin: 5px 0 7px;
}
.t-home-contact-row {
    display: flex;
    height: 70px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.t-link-fix {
    color: black;
}
.t-home-contact-name {
    font-size: 3rem;
    font-weight: 800;
}
.t-home-contact-row-item {
    margin-left: 10px;
}
.t-home-statement {
    width: 95%;
    color: var(--mobile-secondary);
    font-size: 1.7rem;
    text-align: center;
    margin-bottom: 10px;
}
.t-home-info-top {
    font-size: 1.3rem;
    text-align: center;
    line-height: 0.9;
    margin-bottom: 10px;
}
.t-home-info-bottom {
    width: 90%;
    text-align: center;
}
.t-home-actions-container {
    width: 98%;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
}
.t-home-actions-item {
    /* height: 330px; */
    /* min-height: 180px; */
    width: 130px;
    background-color: var(--secondary);
    margin: 3px;
    border-radius: 10px;
    padding: 7px 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-home-actions-text {
    margin-top: 5px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
}