.t-contact-container {
    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-contact-header {
    font-size: 3rem;

    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-contact-item {
    width: 80vw;
    height: 10vh;

    background-color: var(--primary);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    font-size: 1.8rem;
    text-decoration: none;
    color: black;
}
.t-contact-item-icon {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-contact-item-info, .t-contact-item-schedule {
    width: 80%;
    text-align: center;
    padding: 4px 0;
}
.t-contact-item-schedule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.t-contact-testimonial-button {
    width: 90vw;
    /* height: 10vh; */
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.t-contact-testimonial-header {
    margin-bottom: 15px;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1;
}
.t-toggle-testimonial-button {
    text-transform: none;
    /* height: 6vh; */
    /* width: 70vw; */
    padding: 10px 15px;
    font-size: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* backdrop */

.t-contact-testimonial-backdrop {
    background-color: white;
    height: 55vw;
    width: 90vw;
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-contact-backdrop-header {
    width: 100%;
    height: 13%;
    background-color: var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.t-contact-backdrop-header-item {
    margin-left: 11px;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--primary-dark);
}
.t-contact-backdrop-item {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-contact-backdrop-top {
    width: 90%;
    height: 17%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.t-contact-backdrop-date {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-contact-backdrop-body {
    width: 100%;
    height: 44%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-contact-backdrop-submit {
    width: 100%;
    height: 17%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.t-contact-backdrop-feedback {
    width: 100%;
    height: 9%;

    text-align: center;
    font-size: 0.65rem;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
}
.t-contact-testimonial-success {
    font-size: 1.5rem;
    text-align: center;
}