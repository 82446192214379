@import './desktop/NavStyle.css';
@import './desktop/AboutStyle.css';
@import './mobile/AboutStyle.css';
@import './desktop/HomeStyle.css';
@import './mobile/HomeStyle.css';
@import './desktop/LotStyle.css';
@import './desktop/DirectorStyle.css';
@import './desktop/FooterStyle.css';
@import './desktop/GalleryStyle.css';
@import './Tablet/GalleryStyle.css';
@import './Tablet/NavStyle.css';
@import './desktop/ContactStyle.css';
@import './desktop/AdminStyle.css';
@import './desktop/InventoryStyle.css';
@import './desktop/NewsStyle.css';

@import './desktop/LotListStyle.css';
@import './desktop/Practice.css';
@import './desktop/AdminContext.css';

/* #root {
    height: 100%;
} */
html, body { 
    height: 100vh;
    width: 100%;
    margin: 0;
    /* position: relative;
    min-height: 100%; */
    background-color: white;
}

@media screen and (min-width: 600px) {
    html, body {
        background-attachment:fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('./../components/images/background1.jpg');
        font-family: 'Times New Roman', Times, serif;
    }
  }

.admin-auth-form {
    background-color: rgba(255,255,255,0.9);
    width: 45%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 2vh 0;
}
.desktop-login {
    /* background-color: springgreen; */
    padding: 50vh;
}
::-webkit-scrollbar {
    display: none;
}
.App {
    height: 100%;
    width: 100%;
    /* margin-top: 50px; */
    display: flex; 
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}