.t-about-container {
    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

/* container content */

.t-about-options {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    /* width: 750px; */
    /* margin-top: 5px; */
}
.t-about-options-item {
    font-size: 1.4rem;
    /* font-weight: 600; */
    color: rgba(255, 255, 255, 0.788);
    line-height: 0.92;
    /* padding: 5px 0; */
    text-transform: none;
}
.t-about-content-container {
    width: 100%;
    height: 76vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* overflow: scroll; */
}
.t-about-indicator {
    font-size: 2.5rem;
    font-weight: 600;
    height: 7vh;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px 0 8px */
}

/* standard content */

.t-about-gl-pic {
    height: 260px;
}
.t-about-standard-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.t-about-info-section {
    width: 90%;
    font-size: 1.4rem;
    line-height: 0.9;
    margin-bottom: 10px;
    text-align: center;
    flex-shrink: 0;
}
.t-about-bottom-row {
    width: 95%;
    min-height: 260px;
    flex-shrink: 0;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.t-about-bottom-row-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.t-about-info-signature {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-size: 2rem;

}
.t-about-info-sub-signature {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    line-height: 0.9;
    padding: 15px 0 0;
}

/* news content */

.t-about-news-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.t-news-card-container {
    width: 75%;
    padding: 20px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    margin-bottom: 10px;
    border-radius: 10px;
}
.t-news-card-date {
    color: var(--primary-dark);
    font-size: 1.5rem;
    /* margin-bottom: 2px; */
}
.t-news-card-title {
    width: 95%;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 8px;
}
.t-news-card-content {
    line-height: 0.9;
    width: 90%;
    text-align: center;
    font-size: 1.7rem;
}

/* faqs */

.t-about-faq-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.t-about-faq-disclaimer {
    width: 95%;
    text-align: center;
    font-size: 1.05rem;
    line-height: 0.95;
    margin-bottom: 15px;
}
.t-about-panel-quesiton {
    font-size: 1.3rem;
}

/* Legal */

.t-about-legal-container {
    height: 100%;
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: scroll;
}
.t-about-legal-header {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2px;
}
.t-about-legal-content {
    margin-bottom: 8px;
    font-size: 1.3rem;
}
.t-about-legal-main-content {
    line-height: 1;
    font-size: 1.3rem;
}
.t-about-legal-title {
    width: 100%;
    text-align: center;

}