/* container content */

.m-about-container {
    width: 100vw;
    height: 87vh;
    background-color: var(--mobile-bg);
}
.m-about-options {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
}
.m-about-options-item {
    font-size: 1.1rem;
    /* font-weight: 600; */
    color: rgba(255, 255, 255, 0.788);
    line-height: 0.92;
    /* padding: 5px 0; */
    text-transform: none;
}
.m-about-content-container {
    width: 100%;
    height: 76vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* overflow: scroll; */
}
.m-about-indicator {
    font-size: 1.13rem;
    font-weight: 600;
    height: 5vh;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 5px 0 8px */
}

/* standard content */

/* .m-about-header {

} */
.m-about-gl-pic {
    height: 200px;
}
.m-about-standard-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-about-info-section {
    width: 95%;
    font-size: 1.1rem;
    line-height: 0.9;
    margin-bottom: 10px;
    text-align: center;
}
.m-about-bottom-row {
    width: 95%;
    height: 200px;
    margin-top: 18px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.m-about-bottom-row-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.m-about-info-signature {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.4rem;

}
.m-about-info-sub-signature {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    line-height: 0.9;
    padding-bottom: 10px;
}

/* news content */

.m-about-news-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-news-card-container {
    width: 95%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    margin-bottom: 10px;
    border-radius: 10px;
}
.m-news-card-date {
    color: var(--primary-dark);
    font-size: 0.9rem;
    width: 50%;
    text-align: center;
    /* margin-bottom: 2px; */
}
.m-news-card-title {
    width: 95%;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 6px;
}
.m-news-card-content {
    line-height: 0.9;
    width: 90%;
    text-align: center;
    font-size: 1.2rem;
}

/* faqs */

.m-about-faq-container {
    height: 100%;
    width: 95%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-about-faq-disclaimer {
    width: 95%;
    text-align: center;
    font-size: 0.9rem;
    line-height: 0.95;
    margin-bottom: 5px;
}

/* Legal */

.m-about-legal-container {
    height: 100%;
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    overflow: scroll;
}
.m-about-legal-header {
    font-size: 1.13rem;
    font-weight: 600;
    margin-bottom: 2px;
}
.m-about-legal-content {
    margin-bottom: 8px;
}
.m-about-legal-main-content {
    line-height: 1.1;
}
.m-about-legal-title {
    width: 100%;
    text-align: center;
}