.admin-d-context-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.863);
    /* padding-bottom: 1vh; */
    width: 100%;
    height: 100%;
}
.admin-d-context-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: skyblue;
    font-size: 2rem;
    /* padding: 1.5% 0; */
    /* background-color: white; */
    height: 15%;
    text-transform: uppercase;
    width: 95%;
}
/* .new-admin-d-lot-content-container {
 */
.admin-d-context-items-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
}
.admin-d-context-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-right: 2%; */
}
/* new-admin-d-add-lot-backdrop-name */
.admin-d-context-home-item {
    /* width: 25%; */
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.admin-d-context-option-item {
    font-size: 0.8rem;
    color: rgb(97, 187, 223);
}
.admin-d-option-fix {
    display: flex;
    flex-direction: column;
}
.admin-d-context-button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-admin-d-location-sharon-form-item {
    width: 30%;
    /* background-color: tomato; */
}
/* new-admin-d-location-estate-form-row */
.admin-d-context-success-container {
    height: 50%;
    width: 50%;
    background-color: skyblue;
    display: flex;
    justify-content: center;
    align-items: center;
}
.admin-final-d-lot-card-container {
    height: 60%;
    width: 60%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.admin-final-d-lot-card-header-contaner {
    height: 15%;
    width: 100%;
    background-color: skyblue;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.admin-final-d-lot-card-header-item{
    margin: 0 2vw;
    color: black;
    font-size: 2rem;
}

.admin-final-d-lot-card-header-item2 {
    margin: 0 2vw;
    color: black;
    font-size: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 25%;
    align-items: center;
}
/* admin-final-d-lot-card-form-row-item */
.admin-final-d-lot-card-body-container {
    height: 70%;
    width: 100%;
    color: black;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.admin-final-d-lot-card-form-container {
    width: 60%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.admin-final-d-lot-card-form-row {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}
.admin-final-d-lot-card-form-row1{
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.admin-final-d-lot-card-form-comments-row {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}
/* .lot-card-d-location-menu-container {
    
} */
.admin-final-d-lot-card-form-comments-item {
    width: 80%;
}
.admin-final-d-lot-card-form-date-row {
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
}
.myfacecolors {
    background-color: rgb(44, 7, 7);
}
/* admin-final-d-lot-card-header-item */
.admin-final-d-lot-card-form-row-item {
    height: 100%;
    width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
}
.admin-final-d-lot-card-form-row-item5 {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
}
.admin-final-d-lot-card-form-row-date {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.admin-final-d-lot-card-divider-container {
    width: 1%;
    height: 80%;
    background-color: skyblue;
}
.admin-final-d-lot-card-picture-container {
    width: 39%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: slateblue; */
    /* opacity: 90%; */
}
.admin-d-image-delete-icon, .admin-d-image-buffer {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    /* background-color: snow; */
}
.admin-d-image-upload-icon {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.admin-d-final-picture-holder {
   height: 75%;
   width: 90%; 
   /* background-color: slategray; */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.admin-final-d-sold-check {
    font-size: 2rem;
    font-weight: 600;
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.admin-final-d-lot-card-footer-container {
    width: 100%;
    height: 15%;

    color: black;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.admin-final-d-lot-card-footer-item1 {
    width: 60%;
    height: 100%;
    /* background-color: tomato; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.admin-final-d-lot-card-footer-item2 {
    width: 37%;
    margin-right: 3%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.admin-context-created-at-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 60%;
    justify-content: space-between;
    align-items: center;
}
/* .admin-context-created-at-item {

} */
/* admin-final-d-lot-card-picture-container */
.myfixlol {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: turquoise;
}
.admin-final-d-lot-card-footer-item3 {
    width: 60%;
    height: 100%;
    background-color: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
}
.waskywilly {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}


.admin-final-d-lot-card-form-row-item6 {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
/* admin-final-d-lot-card-header-item2 */
.admin-context-d-delete-button {
    color: red;
    /* background-color: rgba(0, 0, 0, 0.377); */
    border-radius: 3vh;
    font-size: 1.2rem;
    padding: 0 1vh;
    font-weight: 600;
}
.admin-final-d-context-button-delete {
    margin-right: 5%;
}
.d-delete-lot-warning-container {
    height: 30%;
    width: 30%;
    background-color: rgb(173, 0, 0);
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-delete-lot-warning-padding {
    height: 98%;
    width: 98%;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.d-delete-lot-warning-top-row {
    width: 80%;
    height: 15%;
    /* background-color: turquoise; */

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}
.d-delete-lot-warning-bottom-row {
    height: 20%;
    width: 80%;
    /* background-color: violet; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
/* container-d-lotcard font */
.d-delete-lot-warning-bottom-item {
    /* background-color: teal; */
    height: 50%;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.d-delete-lot-warning-middle-row {
    /* background-color: teal; */
    height: 15%;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.delete-sharon-details-d {
    height: 100%;
    width: 100%;
    /* background-color: thistle; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.delete-sharon-details-d-item {
    margin: 0 0.3vw;
    font-size: 1.1rem;
}
.d-delete-lot-warning-deleting-row {
    margin-top: 18%;
    height: 7%;
    width: 100%;
    /* background-color: red; */
}
.card-delete-success-backdrop-container {
    /* height: 20%;
    width: 20%; */
    /* padding: 1.5vh; */
    background-color: white;
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.card-delete-actual-button-confirm {
    font-size: 1.1rem;
    color: red;
    /* color: rgba(189, 31, 31, 0.712) */
}
.card-delete-success-backdrop-header {
    background-color: skyblue;
    padding: 1.5vh 1vw 1vh;
    /* width: 100%; */
}
/* admin-final-d-lot-card-footer-container */
/* .card-delete-success-backdrop-check {

} */

.new-d-context-sold-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.new-d-context-sold-header {
    margin-right: 0.3vw;
}

/* admin-d-testimonials-control-item */

.d-a-messages-container {
    /* background-color: white; */
    width: 95%;
    height: 100%;
}
.d-a-messages-header {
    color: white;
    width: 100%;
    font-size: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-messages-content-container {
    min-height: 60vh;
    width: 100%;

    background-color: white;
}
.d-a-messages-content-header {
    background-color: skyblue;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.4rem;
}
.d-a-messages-content-body {
    width: 70%;
    text-align: center;
}
.d-a-messages-content-date {
    width: 20%;
    text-align: center;
}
.d-a-messages-content-delete {
    width: 10%;
    text-align: center;
}
.d-a-message-card-container {
    width: 100%;
    min-height: 10vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(135, 207, 235, 0.74);
    margin-top: 1vh;
}
.d-a-m-c-body {
    width: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-a-m-c-body-fix {
    width: 90%;
}
.d-a-m-c-date {
    width: 20%;
    text-align: center;

}
.d-a-m-c-delete {
    width: 10%;
    text-align: center;
}
.d-a-messages-delete-all-container {
    height: 40vh;
    width: 40vh;

    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-a-delete-all-button {
    color: red;
    font-weight: 800;
}
.d-a-messages-delete-all-header {
    height: 15%;
    width: 100%;

    background-color: red;
    color: white;
    font-size: 1.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-messages-delete-all-header-item1 {
    padding-left: 1vh;
}
/* .d-a-messages-delete-all-header-item2 {

} */
/* admin-d-context-items-container */
.d-a-messages-delete-all-question {
    width: 90%;
    height: 40%;
    color: black;
    /* font-weight: 800; */
    font-size: 1.3rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.d-a-messages-delete-all-buttons {
    width: 90%;
    height: 35%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-messages-delete-all-feedback {
    width: 100%;
    height: 10%;
    /* background-color: blue; */
}
.d-a-messages-none {
    width: 100%;
    height: 10vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.d-a-news-container {
    width: 95%;
    height: 100%;
}
.d-a-news-header {
    color: white;
    width: 100%;
    font-size: 2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1vh;
}
.d-a-news-content-container {
    min-height: 60vh;
    width: 100%;

    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-a-news-content-header {
    width: 100%;
    background-color: skyblue;

    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-news-content-header-listing {
    width: 85%;
    text-align: center;
}
.d-a-news-content-header-options {
    width: 15%;
    text-align: center;
}
.d-a-news-backdrop-container {
    height: 50vh;
    width: 50vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-a-news-backdrop-header {
    background-color: skyblue;
    height: 13%;
    width: 100%;

    font-size: 1.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-news-backdrop-header-item1 {
    padding-left: 1vw;
}

.d-a-news-backdrop-body {
    height: 65%;
    width: 95%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}
.d-a-news-backdrop-submit {
    height: 15%;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-news-backdrop-feedback {
    height: 7%;
    width: 100%;

    /* background-color: slateblue; */
}
.d-a-news-listings-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-a-news-card-container {
    padding: 2vh 0;
    margin-top: 1vh;
    width: 99%;
    background-color: rgba(135, 207, 235, 0.5);
    height: 100%;
    /* min-height: 10vh; */
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
}
.d-a-news-card-details, .d-a-news-card-divider, .d-a-news-card-options {
    height: 100%
}
.d-a-news-card-details {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1vh 0;
} 
.d-a-news-card-options {
    width: 15%;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.d-a-news-card-title {
    font-size: 1.4rem;
}
.d-a-news-card-date {
    font-size: 0.7rem;
}
.d-a-news-card-content {
    width: 70%;
    text-align: center;
    font-size: 1.1rem;
}
.d-a-new-buttons-change {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 20vh;
    justify-content: space-between;
}
.d-a-news-del-button {
    margin-top: 2vh;

}
.d-a-news-save-button {
    margin-bottom: 3vh;
}
.d-a-delete-news-container {
    height: 40vh;
    width: 40vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.d-a-delete-news-header {
    width: 100%;
    height: 15%;
    font-size: 1.5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: red;
    color: white;
}
.d-a-delete-news-header-item {
    padding: 0 1vw;
}
.d-a-delete-news-body {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    width: 90%;
    text-align: center;
    height: 50%;
}
.d-a-delete-news-buttons {
    height: 35%;
    width: 60%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.d-a-delete-news-feedback {
    width: 100%;
    height: 10%;


}
.d-a-delete-news-news {
    font-size: 1.2rem;
}
.d-a-news-card-successful-delete {
    height: 100%;
    width: 100%;

    font-size: 2rem;
    text-align: center;
}