.m-admin-container {
    width: 100vw;
    height: 87vh;
    background-color: var(--mobile-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-login-container{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-login-header {
    font-size: 2.2rem;
    width: 90%;
    margin: 3vh 0 2.5vh;
    height: 12vh;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.m-login-error {
    font-size: 1.4rem;
    font-weight: 800;
    color: rgb(250, 25, 25);
}