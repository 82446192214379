@import './Authentication/Authentication.css';
@import './Nav/Nav.css';
@import './Home/Home.css';
@import './About/About.css';
@import './Gallery/Gallery.css';
@import './Directors/Directors.css';
@import './Contact/Contact.css';
@import './Inventory/Inventory.css';
@import './Admin/Admin.css';

:root {
    --primary: rgba(150, 208, 231, 0.849);
    --primary-dark: rgba(41, 87, 105, 0.849);
    --secondary: rgb(150, 208, 231);
    --tablet-bg: rgba(255,255,255,0.8);
    --mobile-bg: rgb(249, 253, 253);
    --mobile-secondary: rgb(42, 98, 124);
}

.m-scroll-fix {
    display: flex;
    flex-shrink: 0;
    height: 25px;
    width: 1px;
    /* width: 1px;
    padding: 13px 1px; */
}
.m-scroll-fix2 {
    flex-shrink: 0;
    border-radius: 0 0 13px 13px;
    width: 98%;
    height: 15px;
    background-color: var(--primary);
    margin-bottom: 15px;
    font-size: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
