.t-inventory-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);
}

/* choose location */

.t-inventory-choose-header {
    /* height: 10vh; */
    text-align: center;
    font-size: 2rem;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.t-inventory-choose-top, .t-inventory-choose-bottom {
    display: flex;
    flex-direction: row;
}
.t-inventory-choose-button {
    height: 100%;
    width: 100%;
    line-height: 1;
    display: flex;
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
}
/* location chosen */

.t-inventory-location-header {
    min-height: 8vh;
    width: 96vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.t-inventory-scroll-container {
    height: 82vh;
    width: 98vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.t-inventory-current-location {
    font-size: 2.5rem;
    /* padding: 5px 0; */
}
.t-inventory-sharon-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
}
.t-inventory-knollwood-filter {
    font-size: 2.5rem;
    /* margin-right: 10px; */
    text-align: center;
    text-transform: none;
}
.t-inventory-knollwood-category {
    text-align: left;
    padding: 3px 0 3px 10px;
    font-size: 2rem;
    font-weight: 600;
    background-color: var(--primary);
}
.t-inventory-knollwood-item {
    width: 100%;
    font-size: 2rem;
    text-align: right;
    padding: 5px 10px 5px 0;
}

/* inventory card */

.t-inventory-card-container {
    /* min-height: 5vh; */
    width: 95vw;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.t-inventory-card-top, .t-inventory-estate-top, .t-inventory-sold-top, .t-inventory-shara-top {
    /* height: 20px; */
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.95rem;
}
.t-inventory-card-top-section, .t-inventory-card-bottom-section, .t-inventory-card-top-name, .t-inventory-card-bottom-name {
    width: 30%;
}
.t-inventory-card-top-number, .t-inventory-card-bottom-number {
    width: 34%;
}
.t-inventory-card-top-spaces, .t-inventory-card-bottom-spaces {
    width: 24%;
}
.t-inventory-card-bottom, .t-inventory-estate-bottom, .t-inventory-sold-bottom, .t-inventory-shara-bottom {
    /* min-height: 40px; */
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.t-inventory-card-bottom-section {
    font-size: 1.8rem;
}
.t-inventory-beersheba-header {
    font-size: 1.8rem;
}

.t-inventory-section-card-fix {
    font-size: 0.8rem;
}
.t-inventory-card-bottom-number, .t-inventory-card-bottom-spaces, .t-inventory-card-bottom-price {
    font-size: 1.6rem;
}

.t-inventory-logo {
    height: 100px;
    width: 100px;
    position: absolute;
}
.t-inventory-card-bottom-name {
    font-size: 1.6rem;
}
.t-inventory-estate-top-location, .t-inventory-estate-bottom-location {
    width: 68%;
}
.t-inventory-estate-top-price, .t-inventory-estate-bottom-price, .t-inventory-card-top-price, .t-inventory-card-bottom-price {
    width: 30%;
}


.t-inventory-sold-top, .t-inventory-sold-bottom {
    color: black;
}
.t-inventory-sold-top-location, .t-inventory-sold-bottom-location {
    width: 26%;
}
.t-inventory-sold-top-section, .t-inventory-sold-bottom-section {
    width: 29%;
}
.t-inventory-sold-top-number, .t-inventory-sold-bottom-number {
    width: 20%;
}
.t-inventory-sold-bottom-spaces, .t-inventory-sold-top-spaces {
    width: 25%;
}
.t-inventory-shara-item {
    width: 40%;
}
.t-inventory-shara-bottom {
    font-size: 1.8rem;
}
.t-inventory-estate-bottom-location, .t-inventory-estate-bottom-price {
    font-size: 1.6rem;
}
.t-inventory-sold-bottom {
    font-size: 1.4rem;
}

/* details */

.t-details-container {
    /* min-height: 80vh; */
    background-color: var(--mobile-bg);
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* overflow: auto; */
    /* z-index: 20; */
}
.t-details-header {
    min-height: 9vh;
    width: 95%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: tomato; */
}
.t-details-header-item {
    font-size: 2.8rem;
}
.t-details-picture-container {
    width: 97vw;
    min-height: 42vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.t-details-info-container {
    width: 97vw;
    min-height: 20vh;
    /* background-color: tomato; */
}
.t-details-sharon-top-row, .t-details-sharon-bottom-row {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}
.t-details-sharon-top-location, .t-details-sharon-top-section, .t-details-sharon-top-number, .t-details-sharon-bottom-spaces, .t-details-sharon-bottom-price, .t-details-estate-top-category, .t-details-estate-top-location {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.t-details-sharon-top-location {
    width: 30%;
}
.t-details-sharon-top-section {
    width: 40%;
}
.t-details-sharon-top-number {
    width: 25%;
}
.t-details-sharon-top-label, .t-details-sharon-bottom-label {
    font-size: 1rem;
    margin-bottom: 3px;
}
.t-details-sharon-top-info, .t-details-sharon-bottom-info {
    font-size: 2.25rem;
    margin-top: 3px;
}
.t-details-estate-top-info {
    font-size: 1.25rem;
    margin-top: 3px;
}
.t-details-sharon-bottom-spaces, .t-details-sharon-bottom-price {
    min-width: 40%;
}
.t-details-shara-full-row {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.t-details-estate-top-location {
    width: 95%;
    text-align: center;
}
.t-details-other-top-location {
    width: 95%;
    text-align: center;
}
.t-details-number-fix {
    font-size: 0.85rem;
    margin-top: 5px;
}
.t-details-comments-container {
    width: 100%;

    /* background-color: tomato; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.t-details-comments-header {
    padding: 20px 0 3px;
    font-size: 1rem;
    width: 100%;
    text-align: center;
}
.t-details-comment {
    /* padding-bottom: 8px; */
    width: 95vw;
    margin: auto;
    font-weight: 600;
    /* line-height: 0.95; */
    font-size: 1.6rem;
    color: var(--mobile-secondary);
    text-align: center;
}
.t-details-shara-comments-header {
    padding: 20px 0 3px;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    margin-top: -55px;
}
.t-details-shara-comment {
    margin-top: -55px;
    /* padding-bottom: 8px; */
    width: 95vw;
    margin: auto;
    font-weight: 600;
    /* line-height: 0.95; */
    font-size: 2rem;
    color: var(--mobile-secondary);
    text-align: center;
}


/* messages */

.t-details-backdrop-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-details-contact-container {
    margin-top: 2vw;
    height: 60vw;
    width: 96vw;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-details-contact-header {
    width: 100%;
    height: 15%;

    background-color: var(--primary);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.t-details-contact-header-item {
    color: black;
    margin: 0 8px;
    font-size: 2.5rem;
}
.t-details-contact-brand {
    height: 15%;
    width: 95%;

    font-size: 1.6rem;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
}
.t-details-contact-item {
    height: 15%;
    width: 65%;
    border-radius: 10px;
    background-color: var(--primary);
    margin-bottom: 8px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.t-details-contact-icon {
    margin-left: 5px;
    width: 10%;
}
.t-details-contact-info {
    margin-right: 5px;
    font-size: 1.5rem;
    color: black;
    width: 90%;
    text-align: center;
}
.t-details-contact-or {
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    margin: 5px 0 13px;
}
.t-details-button-style {
    font-size: 1.5rem;
}
.t-details-contact-message-body {
    height: 60%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    /* background-color: tomato; */
}
.t-details-contact-message-submit {
    width: 45%;
    height: 15%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.t-details-successful-message {
    width: 90%;
    text-align: center;
    margin-top: 30px;
    font-size: 1.5rem;
    color: var(--mobile-secondary);
}