.m-inventory-container {
    width: 100vw;
    height: 87vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--mobile-bg);
}

/* choose location */

.m-inventory-choose-header {
    /* height: 10vh; */
    text-align: center;
    font-size: 1.7rem;
    margin: 10px 0 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-inventory-choose-button {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}
/* location chosen */

.m-inventory-location-header {
    min-height: 8vh;
    width: 96vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.m-inventory-scroll-container {
    height: 79vh;
    width: 98vw;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: scroll;
}
.m-inventory-current-location {
    font-size: 1.7rem;
    /* padding: 5px 0; */
}
.m-inventory-sharon-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 7px;
}
.m-inventory-knollwood-filter {
    font-size: 1.7rem;
    /* margin-right: 10px; */
    text-align: center;
    text-transform: none;
}
.m-inventory-knollwood-category {
    text-align: left;
    padding: 3px 0 3px 10px;
    font-size: 1.3rem;
    font-weight: 600;
    background-color: var(--primary);
}
.m-inventory-knollwood-item {
    width: 100%;
    font-size: 1.7rem;
    text-align: right;
    padding: 0 10px;
}

/* inventory card */

.m-inventory-card-container {
    /* min-height: 5vh; */
    width: 98vw;
    padding: 7px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.m-inventory-card-top, .m-inventory-estate-top, .m-inventory-sold-top, .m-inventory-shara-top {
    height: 20px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 0.75rem;
}
.m-inventory-card-top-section, .m-inventory-card-bottom-section, .m-inventory-card-top-name, .m-inventory-card-bottom-name {
    width: 39%;
}
.m-inventory-card-top-number, .m-inventory-card-bottom-number {
    width: 34%;
}
.m-inventory-card-top-spaces, .m-inventory-card-bottom-spaces {
    width: 24%;
}
.m-inventory-card-bottom, .m-inventory-estate-bottom, .m-inventory-sold-bottom, .m-inventory-shara-bottom {
    min-height: 40px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.m-inventory-card-bottom-section {
    font-size: 1.3rem;
}
.m-inventory-beersheba-header {
    font-size: 1rem;
}

.m-inventory-section-card-fix {
    font-size: 0.8rem;
}
.m-inventory-card-bottom-number, .m-inventory-card-bottom-spaces {
    font-size: 1.1rem;
}

.m-inventory-logo {
    height: 70px;
    width: 70px;
    position: absolute;
}
.m-inventory-card-bottom-name {
    font-size: 1.15rem;
}
.m-inventory-estate-top-location, .m-inventory-estate-bottom-location {
    width: 68%;
}
.m-inventory-estate-top-price, .m-inventory-estate-bottom-price {
    width: 30%;
}


.m-inventory-sold-top, .m-inventory-sold-bottom {
    color: black;
}
.m-inventory-sold-top-location, .m-inventory-sold-bottom-location {
    width: 26%;
}
.m-inventory-sold-top-section, .m-inventory-sold-bottom-section {
    width: 29%;
}
.m-inventory-sold-top-number, .m-inventory-sold-bottom-number {
    width: 20%;
}
.m-inventory-sold-bottom-spaces, .m-inventory-sold-top-spaces {
    width: 25%;
}
.m-inventory-shara-item {
    width: 40%;
}
/* details */

.m-details-container {
    /* min-height: 80vh; */
    background-color: var(--mobile-bg);
    width: 100vw;
    height: 87vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* overflow: auto; */
    /* z-index: 20; */
}
.m-details-header {
    min-height: 7vh;
    width: 95%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* background-color: tomato; */
}
.m-details-header-item {
    font-size: 1.5rem;
}
.m-details-picture-container {
    width: 97vw;
    min-height: 32vh;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-details-info-container {
    width: 97vw;
    min-height: 13vh;
    /* background-color: tomato; */
}
.m-details-sharon-top-row, .m-details-sharon-bottom-row {
    height: 50%;
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

}
.m-details-sharon-top-location, .m-details-sharon-top-section, .m-details-sharon-top-number, .m-details-sharon-bottom-spaces, .m-details-sharon-bottom-price, .m-details-estate-top-category, .m-details-estate-top-location {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-details-sharon-top-location {
    width: 30%;
}
.m-details-sharon-top-section {
    width: 40%;
}
.m-details-sharon-top-number {
    width: 25%;
}
.m-details-sharon-top-label, .m-details-sharon-bottom-label {
    font-size: 0.8rem;
}
.m-details-sharon-top-info, .m-details-sharon-bottom-info {
    font-size: 1.25rem;
    margin-top: 3px;
}
.m-details-estate-top-info {
    font-size: 1.25rem;
    margin-top: 3px;
}
.m-details-sharon-bottom-spaces, .m-details-sharon-bottom-price {
    min-width: 40%;
}
.m-details-shara-full-row {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.m-details-estate-top-location {
    width: 95%;
    text-align: center;
}
.m-details-other-top-location {
    width: 95%;
    text-align: center;
}
.m-details-number-fix {
    font-size: 0.85rem;
    margin-top: 5px;
}
.m-details-comments-container {
    width: 100%;

    /* background-color: tomato; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.m-details-comments-header {
    padding: 20px 0 3px;
    font-size: 0.8rem;
    width: 100%;
    text-align: center;
}
.m-details-comment {
    padding-bottom: 8px;
    width: 95vw;
    margin: auto;
    font-weight: 600;
    /* line-height: 0.95; */
    font-size: 1.2rem;
    color: var(--mobile-secondary);
    text-align: center;
}


/* messages */

.m-details-backdrop-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-details-contact-container {
    margin-top: 2vw;
    height: 75vw;
    width: 96vw;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.m-details-contact-header {
    width: 100%;
    height: 15%;

    background-color: var(--primary);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-details-contact-header-item {
    color: black;
    margin: 0 8px;
    font-size: 1.4rem;
}
.m-details-contact-brand {
    height: 12%;
    width: 95%;

    font-size: 1.1rem;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
}
.m-details-contact-item {
    height: 18%;
    width: 85%;
    border-radius: 10px;
    background-color: var(--primary);
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-details-contact-icon {
    margin-left: 5px;
    width: 10%;
}
.m-details-contact-info {
    margin-right: 5px;
    font-size: 1rem;
    color: black;
    width: 90%;
    text-align: center;
}
.m-details-contact-or {
    width: 100%;
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 5px;
}
.m-details-button-style {
    font-size: 1.1rem;
}
.m-details-contact-message-body {
    height: 60%;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: auto; */
    /* background-color: tomato; */
}
.m-details-contact-message-submit {
    width: 65%;
    height: 15%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.m-details-successful-message {
    width: 90%;
    text-align: center;
    margin-top: 12px;
    font-size: 1.1rem;
    color: var(--mobile-secondary);
}

.m-d-Remembrance-fix {
    /* font-size: 0.9rem; */
    font-stretch: condensed;
}