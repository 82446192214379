.t-directors-container {
    height: 90vh;
    width: 100vw;
    background-color: var(--tablet-bg);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.t-directors-options {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
}
.t-directors-options-item {
    font-size: 1.8rem;
    color: rgba(255, 255, 255, 0.788);
    line-height: 0.92;
    text-transform: none;
}
.t-directors-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    height: 5vh;
    flex-shrink: 0;
}
.t-directors-location {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.4rem;
    height: 4vh;
    flex-shrink: 0;
}

.t-directors-location-header {
    height: 5vh;
    width: 99%;
    background-color: var(--primary);

    border-radius: 20px 20px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.t-directors-header-item {
    /* height: 100%; */
    width: 30%;
    font-size: 1.6rem;
    text-align: center;
}
.t-directors-header-name {
    /* height: 100%; */
    width: 30%;
    font-size: 1.6rem;
    text-align: center;
}
.t-directors-header-number {
    /* height: 100%; */
    width: 25%;
    font-size: 1.6rem;
    text-align: center;
}
.t-directors-header-spaces {
    /* height: 100%; */
    width: 20%;
    font-size: 1.6rem;
    text-align: center;
}
.t-directors-content {
    height: 63vh;
    width: 99%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    background-color: var(--primary);
}
.t-directors-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 99%;
    background-color: white;
    margin: 2px 0;
    padding: 15px 0;
    flex-shrink: 0;
}
.t-directors-card-container:hover {
    background-color: rgba(0,0,0,0);
    /* color: white; */
    font-weight: 600;
}
.t-directors-card-item {
    width: 30%;
    font-size: 2.5rem;
    text-align: center;
}
.t-directors-card-name {
    width: 30%;
    font-size: 2.5rem;
    text-align: center;
}
.t-directors-card-number {
    width: 25%;
    font-size: 1.8rem;
    text-align: center;
}
.t-directors-card-spaces {
    width: 20%;
    font-size: 1.8rem;
    text-align: center;
}
.t-directors-header-price {
    width: 20%;
    font-size: 1.6rem;
    text-align: center;
}
.t-directors-card-price {
    width: 20%;
    font-size: 1.8rem;
    text-align: center;
}
.t-directors-loading-center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.t-directors-bottom {
    flex-shrink: 0;
    border-radius: 0 0 13px 13px;
    width: 98%;
    height: 40px;
    background-color: var(--primary);
    margin-bottom: 15px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.t-directors-beersheba-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.t-directors-beersheba-header {
    font-size: 2.5rem;
}
.t-directors-section-card-fix {
    font-size: 1.8rem;
}